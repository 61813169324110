<template>
  <b-col
    v-if="render && canViewThisAction('read', 'ServiceRequest')"
    class="d-inline"
    lg="6"
  >
    <b-card>
      <b-card-title class="mb-1 fs-21 pure-black">
        Service Requests
      </b-card-title>
      <b-card-sub-title class="mb-2 black-color fs-16">
        Quarterly SR Payment Status
      </b-card-sub-title>

      <chartjs-component-bar-chart
        :height="250"
        :data="dataSeries"
        :options="chartOptions"
      />
    </b-card>
  </b-col>
</template>

<script>
import {
  BCard, BCardTitle, BCardSubTitle, BCol,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { useUtils as useAclUtils } from '@/libs/acl/custom'
import ChartjsComponentBarChart from '../../charts-components/ChartjsComponentBarChart.vue'

const { canViewThisAction } = useAclUtils()

export default {
  components: {
    ChartjsComponentBarChart,
    BCard,
    BCardTitle,
    BCardSubTitle,
    BCol,
  },
  data() {
    return {
      canViewThisAction,
      render: false,
      srCount: 0,
      dataSeries: {},
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        backgroundColor: false,
        hover: {
          mode: 'label',
        },
        layout: {
          padding: {
            top: -15,
            bottom: -25,
            left: -15,
          },
        },
        scales: {
          xAxes: [
            {
              display: true,
              scaleLabel: {
                display: true,
              },
              gridLines: {
                display: true,
                color: 'rgba(200, 200, 200, 0.2)',
                zeroLineColor: 'rgba(200, 200, 200, 0.2)',
              },
              ticks: {
                fontColor: '#000',
                fontWeight: '600',
              },
            },
          ],
          yAxes: [
            {
              display: true,
              scaleLabel: {
                display: true,
              },
              ticks: {
                // stepSize: 100,
                min: 0,
                // max: 400,
                fontColor: '#000',
                fontWeight: '600',
              },
              gridLines: {
                display: true,
                color: 'rgba(200, 200, 200, 0.2)',
                zeroLineColor: 'rgba(200, 200, 200, 0.2)',
              },
            },
          ],
        },
        legend: {
          position: 'right',
          align: 'center',
          labels: {
            usePointStyle: true,
            padding: 25,
            boxWidth: 9,
          },
        },
      },
    }
  },

  beforeMount() {
    this.$http.get('user/operation/service-request/quarterly-payment-status')
      .then(response => {
        this.dataSeries.datasets = response.data.dataSet
        this.dataSeries.labels = response.data.labels

        this.render = true
      })
      .catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  },
}
</script>
