<template>
  <b-col
    class="p-0"
    lg="12"
  >
    <b-card
      v-if="canViewThisAction('read', 'Booking')"
      no-body
      class="mb-0"
    >
      <!-- <b-card-title class="mb-1 fs-18 weight-500">
        <feather-icon
          icon="ListIcon"
          class="mr-75"
          size="20"
        />{{ widgetName }} ({{ totalRows }})
      </b-card-title> -->

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-space_between mb-1 mb-md-0"
          >
            <span class="text-muted">Showing {{ dataMeta.from }} - {{ dataMeta.to }} of {{ dataMeta.of }}</span>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <a
                href="javascript:void(0)"
                class="nav-link nav-link-search"
                @click="showSearchBar = !showSearchBar"
              >
                <feather-icon
                  icon="SearchIcon"
                  size="21"
                  class="mr-50"
                />
                <span class="align-middle">Search</span>
              </a>
              <b-input-group
                v-if="showSearchBar"
                class="input-group-merge mr-50"
              >
                <b-form-input
                  v-model="searchQuery"
                  placeholder="Search..."
                />
                <b-input-group-append is-text>
                  <feather-icon
                    icon="XIcon"
                    class="cursor-pointer"
                    @click="searchQuery = ''; showSearchBar = !showSearchBar"
                  />
                </b-input-group-append>
              </b-input-group>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refBookingsTable"
        class="position-relative event_list"
        :items="tableItems"
        responsive
        :fields="tableColumns"
        primary-key="_id"
        :current-page.sync="currentPage"
        :per-page="perPage"
        :sort-by.sync="sortBy"
        show-empty
        no-sort-reset
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
        :sort-compare="sortCompare"
      >
        <template #cell(stringID)="data">
          <div class="text-nowrap">
            <b-button
              v-if="canViewThisAction('show', 'Booking')"
              v-ripple.400="'rgba(30, 30, 30, 0.15)'"
              variant="link"
              class="weight-600 align-middle detail-view-id"
              :to="{ name: 'appointment-bookings-show', params: { id: data.item._id } }"
            >
              {{ data.item.stringID }}
            </b-button>
            <b-button
              v-else
              v-ripple.400="'rgba(30, 30, 30, 0.15)'"
              variant="none"
              class="text-bold-black align-middle detail-view-id not-button"
            >
              {{ data.item.stringID }}
            </b-button>
          </div>
        </template>

        <template #cell(start)="data">
          <div
            v-if="data.item.timeSlotApplicable === false"
            class="text-nowrap"
          >
            <span>-</span>
          </div>
          <div
            v-else
            class="text-nowrap"
          >
            <span>{{ dateFormat(data.item.start) }}</span>
            <div v-if="data.item.start != data.item.end">
              <span>{{ timeFormatFromDateString(data.item.start) }}</span> - <span>{{ timeFormatFromDateString(data.item.end) }}</span>
            </div>
          </div>
        </template>

        <template #cell(activityType)="data">
          <b-badge
            :variant="`${data.item.activityType.color}`"
            class="small-badge-button small-badge-button-activity-list"
            :style="{ '--bg-color': data.item.activityType.color }"
          >
            <span v-if="data.item.eventID">
              {{ data.item.eventID ? data.item.eventID.name : '' }}
            </span>
            <span v-else>
              {{ data.item.activityType ? data.item.activityType.typeName : '' }}
            </span>
          </b-badge>
        </template>

        <template #cell(customerName)="data">
          <div class="text-nowrap">
            <span>{{ data.item.customerName }}</span>
            <br>
            <span>{{ data.item.customerContact ? data.item.customerContact : data.item.customerEmail }}</span>
          </div>
        </template>

        <template #cell(agentName)="data">
          <div class="text-nowrap">
            <span>{{ data.item.agentName }}</span>
            <br>
            <span>{{ data.item.agentCode }}</span>
          </div>
        </template>

        <template #cell(createdBy)="data">
          <div class="text-nowrap">
            <span>{{ data.item.createdBy.name }}</span>
            <br>
            <span class="">{{ dateFormatWithTime(data.item.createdAt) }}</span>
          </div>
        </template>

        <!-- Column: Status -->
        <template #cell(status)="data">
          <b-badge
            :variant="`light-${resolveBookingStatusVariant(data.item.status)}`"
            class="text-capitalize"
          >
            {{ data.item.status.replace("-", " ") }}
          </b-badge>
        </template>

        <template #cell(freshness)="data">
          <span class="text-bold-black">{{ dateDifference(data.item.start) }}</span>
        </template>
      </b-table>
    </b-card>

    <div
      class="mx-2 mb-2"
    >
      <b-row>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="12"
          class="d-flex align-items-center justify-content-center pagination-content"
        >

          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            align="center"
            prev-text="Prev"
            next-text="Next"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
              <span>Prev</span>
            </template>
            <template #next-text>
              <span>Next</span>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>

        </b-col>

      </b-row>
    </div>
  </b-col>
</template>

<script>
import {
  BCard, BRow, BCol, BTable, BButton, VBTooltip, BPagination, BBadge, BInputGroup, BInputGroupAppend, BFormInput,
} from 'bootstrap-vue'
import store from '@/store/index'
import moment from 'moment'
import Ripple from 'vue-ripple-directive'
import { useUtils as useAclUtils } from '@/libs/acl/custom'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

const { canViewThisAction } = useAclUtils()

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BTable,
    BButton,
    BPagination,
    BBadge,
    BInputGroup,
    BInputGroupAppend,
    BFormInput,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  props: {
    widgetName: {
      type: [String, null],
      default: '',
    },
    statusOptions: {
      type: [Array, null],
      default: () => [],
    },
    dateOption: {
      type: [String, null],
      default: '',
    },
    dateRangeOption: {
      type: [Array, null],
      default: () => [],
    },
    tableColumns: {
      type: [Array, null],
      default: () => [],
    },
    bookingsCount: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      user: store.state.auth.userData,
      showSearchBar: false,
      searchQuery: '',
      render: false,
      sortBy: 'stringID',
      sortDesc: false,
      isSortDirDesc: true,
      tableItems: [],
      currentPage: 1,
      totalRows: 0,
      perPage: 10,
    }
  },
  computed: {
    dataMeta() {
      let totalPage = 1
      let localItems = 0
      if (this.totalRows) {
        totalPage = Math.ceil(this.totalRows / this.perPage)
        if (totalPage === this.currentPage) {
          localItems = this.totalRows - (this.perPage * (this.currentPage - 1))
        } else {
          localItems = 10
        }
      }
      return {
        from: this.perPage * (this.currentPage - 1) + (this.totalRows ? 1 : 0),
        to: this.perPage * (this.currentPage - 1) + localItems,
        of: this.totalRows,
      }
    },
  },

  watch: {
    searchQuery: {
      immediate: true,
      handler() {
        this.getData()
      },
    },

    // $props: {
    //   handler() {
    //     this.getData()
    //   },
    //   deep: true,
    //   immediate: true,
    // },
    statusOptions: {
      immediate: true,
      deep: true,
      handler() {
        this.getData()
      },
    },
    dateOption: {
      immediate: true,
      deep: true,
      handler() {
        this.getData()
      },
    },
    dateRangeOption: {
      immediate: true,
      deep: true,
      handler() {
        this.getData()
      },
    },
  },

  beforeMount() {
    this.getData()
  },

  methods: {
    canViewThisAction,
    getData() {
      const formData = new FormData()
      formData.append('search', this.searchQuery)
      formData.append('dateOption', this.dateOption)
      formData.append('sortBy', this.sortBy)
      formData.append('sortDesc', this.sortDesc)
      formData.append('dateRangeOption', JSON.stringify(this.dateRangeOption))
      formData.append('statusOptions', JSON.stringify(this.statusOptions))

      this.$http.post('user/appointment/bookings/department/find', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
        .then(response => {
          const { bookings } = response.data
          this.tableItems = bookings
          this.totalRows = bookings.length
          this.$emit('update:bookingsCount', this.totalRows)
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    resolveBookingStatusVariant(status) {
      if (status === 'confirmed') return 'success'
      if (status === 'pending-payment') return 'warning'
      if (status === 'pending-approval') return 'warning'
      if (status === 'waiting-list') return 'secondary'
      if (status === 'draft') return 'secondary'
      if (status === 'cancelled') return 'danger'
      return 'primary'
    },
    dateDifference(date) {
      const timezone = process.env.VUE_APP_TIME_ZONE
      const appointmentDate = moment(date).utcOffset(timezone)
      const today = moment().utcOffset(timezone)

      const difference = appointmentDate.diff(today, 'days')

      if (difference === 0) return 'Today'
      if (difference === 1) return '1 day later'
      if (difference === -1) return '1 day ago'
      if (difference > 1) return `${difference} days later`
      if (difference < -1) return `${Math.abs(difference)} days ago`

      return ''
    },
    // eslint-disable-next-line consistent-return
    sortCompare(aRow, bRow, key) {
      if (key === 'freshness') {
        // eslint-disable-next-line no-nested-ternary
        return aRow.start < bRow.start ? -1 : aRow.start > bRow.start ? 1 : 0
      }
    },
  },
}
</script>
