<template>
  <b-col lg="12">
    <b-card v-if="canViewThisAction('read', 'InventoryRequest')">
      <b-card-title class="mb-1 fs-18 weight-500">
        <feather-icon
          icon="ListIcon"
          class="mr-75"
          size="20"
        />{{ widgetName }} ({{ totalRows }})
      </b-card-title>

      <b-table
        ref="refInventoryRequestTable"
        class="position-relative event_list shadow-table"
        :items="tableItems"
        responsive
        :fields="tableColumns"
        primary-key="_id"
        :current-page.sync="currentPage"
        :per-page="perPage"
        :sort-by.sync="sortBy"
        show-empty
        no-sort-reset
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
        :sort-compare="sortCompare"
      >
        <template #cell(stringID)="data">
          <div class="text-nowrap">
            <b-button
              v-if="canViewThisAction('show', 'InventoryRequest')"
              v-ripple.400="'rgba(30, 30, 30, 0.15)'"
              variant="link"
              class="weight-600 align-middle detail-view-id"
              :to="{ name: 'inventory-inventory-request-view', params: { id: data.item._id } }"
            >
              {{ data.item.stringID }}
              <br>
              <span class="font_normal">fr.</span>
              <b-button
                v-if="canViewThisAction('show', 'ServiceForm')"
                v-ripple.400="'rgba(30, 30, 30, 0.15)'"
                variant="none"
                class="text-bold-primary align-middle detail-view-id"
                :to="{ name: 'operation-service-forms-show', params: { id: data.item.serviceForm } }"
              >
                {{ data.item.serviceFormStringID }}
              </b-button>
              <b-button
                v-else
                v-ripple.400="'rgba(30, 30, 30, 0.15)'"
                variant="none"
                class="weight-400 align-middle detail-view-id not-button"
              >
                {{ data.item.serviceFormStringID }}
              </b-button>
            </b-button>
            <b-button
              v-else
              v-ripple.400="'rgba(30, 30, 30, 0.15)'"
              variant="none"
              class="text-bold-black align-middle detail-view-id not-button"
            >
              {{ data.item.stringID }}
              <span class="font_normal">fr.</span>
              <b-button
                v-if="canViewThisAction('show', 'ServiceForm')"
                v-ripple.400="'rgba(30, 30, 30, 0.15)'"
                variant="none"
                class="text-bold-primary align-middle detail-view-id"
                :to="{ name: 'operation-service-forms-show', params: { id: data.item.serviceForm } }"
              >
                {{ data.item.serviceFormStringID }}
              </b-button>
              <b-button
                v-else
                v-ripple.400="'rgba(30, 30, 30, 0.15)'"
                variant="none"
                class="weight-400 align-middle detail-view-id not-button"
              >
                {{ data.item.serviceFormStringID }}
              </b-button>
            </b-button>
          </div>
        </template>

        <template #cell(createdBy)="data">
          <div class="text-nowrap">
            <span class="">{{ data.item.createdBy.name }}</span>
          </div>
        </template>

        <template #cell(serviceDate)="data">
          <div class="text-nowrap">
            <span class="">{{ dateFormatWithTime(data.item.serviceDate) }}</span>
          </div>
        </template>

        <template #cell(customerName)="data">
          <div
            class="text-nowrap"
          >
            <span class="">{{ data.item.customerName }}</span>
            <br>
            <span
              v-if="data.item.fileNo"
              class=""
            >
              #{{ data.item.fileNo }}
            </span>
          </div>
        </template>

        <template #cell(stores)="data">
          <div class="">
            <span
              v-for="(option, indx) in data.item.stores"
              :key="indx"
            >
              {{ option.name }}<span v-if="indx + 1 != data.item.stores.length">, </span>
            </span>
          </div>
        </template>

        <!-- Column: Status -->
        <template #cell(status)="data">
          <b-badge
            :variant="`light-${resolveInventoryRequestStatusVariant(data.item.status)}`"
            class="text-capitalize"
          >
            {{ data.item.status }}
          </b-badge>
        </template>

        <template #cell(freshness)="data">
          <span class="text-bold-black">{{ dateDifference(data.item.serviceDate) }}</span>
        </template>
        <template #custom-foot>
          <b-tr>
            <b-td :colspan="tableColumns.length">
              <div class="d-flex justify-content-between w-100">
                <span class="text-muted">Showing {{ dataMeta.from }} - {{ dataMeta.to }} of {{ dataMeta.of }}</span>
                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalRows"
                  :per-page="perPage"
                  align="right"
                  first-number
                  last-number
                  prev-class="prev-item"
                  next-class="next-item"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>
              </div>
            </b-td>
          </b-tr>
        </template>
      </b-table>
    </b-card>
  </b-col>
</template>

<script>
import {
  BCard, BCardTitle, BCol, BTable, BButton, VBTooltip, BPagination, BBadge, BTr, BTd,
} from 'bootstrap-vue'
import store from '@/store/index'
import moment from 'moment'
import Ripple from 'vue-ripple-directive'
import { useUtils as useAclUtils } from '@/libs/acl/custom'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

const { canViewThisAction } = useAclUtils()

export default {
  components: {
    BCard,
    BCardTitle,
    BCol,
    BTable,
    BButton,
    BPagination,
    BBadge,
    BTr,
    BTd,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  props: {
    widgetName: {
      type: [String, null],
      default: '',
    },
    statusOptions: {
      type: [Array, null],
      default: () => [],
    },
    storeOptions: {
      type: [Array, null],
      default: () => [],
    },
    filterOption: {
      type: [String, null],
      default: '',
    },
    dateOption: {
      type: [String, null],
      default: '',
    },
    dateRangeOption: {
      type: [Array, null],
      default: () => [],
    },
    tableColumns: {
      type: [Array, null],
      default: () => [],
    },
  },
  data() {
    return {
      user: store.state.auth.userData,
      render: false,
      sortBy: 'stringID',
      sortDesc: false,
      isSortDirDesc: true,
      tableItems: [],
      currentPage: 1,
      totalRows: 0,
      perPage: 10,
    }
  },
  computed: {
    dataMeta() {
      let totalPage = 1
      let localItems = 0
      if (this.totalRows) {
        totalPage = Math.ceil(this.totalRows / this.perPage)
        if (totalPage === this.currentPage) {
          localItems = this.totalRows - (this.perPage * (this.currentPage - 1))
        } else {
          localItems = 10
        }
      }
      return {
        from: this.perPage * (this.currentPage - 1) + (this.totalRows ? 1 : 0),
        to: this.perPage * (this.currentPage - 1) + localItems,
        of: this.totalRows,
      }
    },
  },

  watch: {
    $props: {
      handler() {
        this.getData()
      },
      deep: true,
      immediate: true,
    },
  },

  beforeMount() {
    this.getData()
  },

  methods: {
    canViewThisAction,
    getData() {
      const formData = new FormData()
      formData.append('filterOption', this.filterOption)
      formData.append('dateOption', this.dateOption)
      formData.append('sortBy', this.sortBy)
      formData.append('sortDesc', this.sortDesc)
      formData.append('dateRangeOption', JSON.stringify(this.dateRangeOption))
      formData.append('statusOptions', JSON.stringify(this.statusOptions))
      formData.append('storeOptions', JSON.stringify(this.storeOptions))

      this.$http.post('user/inventory/inventory-request/find', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
        .then(response => {
          const { inventoryRequests } = response.data
          this.tableItems = inventoryRequests
          this.totalRows = inventoryRequests.length
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    resolveInventoryRequestStatusVariant(status) {
      if (status === 'completed') return 'success'
      if (status === 'cancelled') return 'danger'
      if (status === 'returned') return 'warning'
      if (status === 'collected') return 'secondary'
      if (status === 'open') return 'info'
      return 'primary'
    },
    dateDifference(date) {
      const timezone = process.env.VUE_APP_TIME_ZONE
      const appointmentDate = moment(date).utcOffset(timezone)
      const today = moment().utcOffset(timezone)

      const difference = appointmentDate.diff(today, 'days')

      if (difference === 0) return 'Today'
      if (difference === 1) return '1 day later'
      if (difference === -1) return '1 day ago'
      if (difference > 1) return `${difference} days later`
      if (difference < -1) return `${Math.abs(difference)} days ago`

      return ''
    },
    // eslint-disable-next-line consistent-return
    sortCompare(aRow, bRow, key) {
      if (key === 'freshness') {
        // eslint-disable-next-line no-nested-ternary
        return aRow.serviceDate < bRow.serviceDate ? -1 : aRow.serviceDate > bRow.serviceDate ? 1 : 0
      }
    },
  },
}
</script>
