<template>
  <b-col
    v-if="render && canViewThisAction('read', 'ServiceForm')"
    class="d-inline"
    lg="6"
  >
    <b-card>
      <b-card-title class="mb-1 fs-21 pure-black">
        Service Forms
      </b-card-title>
      <b-card-sub-title class="mb-2 black-color fs-16">
        Pending Status Aging Days
      </b-card-sub-title>

      <div class="d-flex">
        <chartjs-component-polar-area-chart
          :height="250"
          :data="dataSeries"
          :options="chartOptions"
        />

        <div class="legend-container">
          <p
            v-for="(legendValue, index) in dataSeries.labels"
            :key="`legend-badge-${index}`"
            class="mb-0"
          >
            <span
              :style="{ 'background-color': dataSeries.datasets[0].backgroundColor[index] }"
              class="badge"
            />
            <span>{{ legendValue }}</span> <span class="weight-600 legend-item-value">{{ dataSeries.datasets[0].data[index] }}</span>
          </p>
        </div>
      </div>
    </b-card>
  </b-col>
</template>

<script>
import {
  BCard, BCardTitle, BCardSubTitle, BCol,
} from 'bootstrap-vue'
import { $themeColors } from '@themeConfig'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { useUtils as useAclUtils } from '@/libs/acl/custom'
import ChartjsComponentPolarAreaChart from '../../charts-components/ChartjsComponentPolarAreaChart.vue'

const { canViewThisAction } = useAclUtils()

export default {
  components: {
    ChartjsComponentPolarAreaChart,
    BCard,
    BCardTitle,
    BCardSubTitle,
    BCol,
  },
  data() {
    return {
      canViewThisAction,
      render: false,
      sfCount: 0,
      dataSeries: {},
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        responsiveAnimationDuration: 500,
        legend: {
          display: false,
          position: 'right',
          align: 'center',
          labels: {
            usePointStyle: true,
            padding: 20,
            boxWidth: 10,
            fontColor: '#6e6b7b',
            fontSize: 14,
            fontFamily: 'Montserrat',
            // generateLabels(chart) {
            //   const { data } = chart
            //   if (data.labels.length && data.datasets.length) {
            //     return data.labels.map((label, i) => ({
            //       text: `${label} <span class="weight-600 legend-item-value">${chart.data.datasets[0].data[i]}</span>`,
            //       fillStyle: chart.data.datasets[0].backgroundColor[i],
            //       strokeStyle: chart.data.datasets[0].backgroundColor[i],
            //       index: i,
            //     }))
            //   }
            //   return []
            // },
          },
        },
        tooltips: {
          // Updated default tooltip UI
          shadowOffsetX: 1,
          shadowOffsetY: 1,
          shadowBlur: 8,
          shadowColor: 'rgba(0, 0, 0, 0.25)',
          backgroundColor: $themeColors.light,
          titleFontColor: $themeColors.dark,
          bodyFontColor: $themeColors.dark,
        },
        scale: {
          scaleShowLine: true,
          scaleLineWidth: 1,
          ticks: {
            display: false,
            fontColor: '#6e6b7b',
          },
          reverse: false,
          gridLines: {
            display: false,
          },
        },
        animation: {
          animateRotate: false,
        },
      },
    }
  },

  beforeMount() {
    this.$http.get('user/operation/service-form/age')
      .then(response => {
        const result = response.data.data
        let total = 0
        const series = []
        const labels = []
        const colors = []
        result.forEach(element => {
          total += element.totalCount

          series.push(element.totalCount)
          colors.push(element.color)
          labels.push(element.label)
        })

        this.sfCount = total
        this.dataSeries = {
          labels,
          datasets: [
            {
              backgroundColor: colors,
              data: series,
              borderWidth: 0,
            },
          ],
        }

        this.render = true
      })
      .catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  },
}
</script>

  <style scoped>
  .legend-container {
    padding: 1em;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    gap: 10px;
    justify-content: center;
    flex-direction: column;
    font-size: 14px;
  }
  .badge {
    border-radius: 50%;
    padding: 0;
    width: 14px;
    height: 14px;
    display: inline-block;
    margin-right: 5px;
  }
  </style>
