<template>
  <b-col lg="12">
    <b-card v-if="canViewThisAction('read', 'ServiceRequest')">
      <b-card-title class="mb-1 fs-18 weight-500">
        <feather-icon
          icon="ListIcon"
          class="mr-75"
          size="20"
        />{{ widgetName }} ({{ totalRows }})
      </b-card-title>

      <b-table
        ref="refServiceRequestTable"
        class="position-relative event_list shadow-table"
        :items="tableItems"
        responsive
        :fields="tableColumns"
        primary-key="_id"
        :current-page.sync="currentPage"
        :per-page="perPage"
        :sort-by.sync="sortBy"
        show-empty
        no-sort-reset
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
        :sort-compare="sortCompare"
      >
        <template #cell(stringID)="data">
          <div class="text-nowrap">
            <b-button
              v-if="canViewThisAction('show', 'ServiceRequest')"
              v-ripple.400="'rgba(30, 30, 30, 0.15)'"
              variant="link"
              class="weight-600 align-middle detail-view-id"
              :to="{ name: 'operation-service-requests-show', params: { id: data.item._id } }"
            >
              {{ data.item.stringID }}
              <br>
              <span class="font_normal">fr.</span>
              <b-button
                v-if="canViewThisAction('show', 'ServiceForm')"
                v-ripple.400="'rgba(30, 30, 30, 0.15)'"
                variant="none"
                class="text-bold-primary align-middle detail-view-id"
                :to="{ name: 'operation-service-forms-show', params: { id: data.item.serviceForm._id } }"
              >
                {{ data.item.serviceFormStringID }}
              </b-button>
              <b-button
                v-else
                v-ripple.400="'rgba(30, 30, 30, 0.15)'"
                variant="none"
                class="weight-400 align-middle detail-view-id not-button"
              >
                {{ data.item.serviceFormStringID }}
              </b-button>
            </b-button>
            <b-button
              v-else
              v-ripple.400="'rgba(30, 30, 30, 0.15)'"
              variant="none"
              class="text-bold-black align-middle detail-view-id not-button"
            >
              {{ data.item.stringID }}
              <span class="font_normal">fr.</span>
              <b-button
                v-if="canViewThisAction('show', 'ServiceForm')"
                v-ripple.400="'rgba(30, 30, 30, 0.15)'"
                variant="none"
                class="text-bold-primary align-middle detail-view-id"
                :to="{ name: 'operation-service-forms-show', params: { id: data.item.serviceForm._id } }"
              >
                {{ data.item.serviceFormStringID }}
              </b-button>
              <b-button
                v-else
                v-ripple.400="'rgba(30, 30, 30, 0.15)'"
                variant="none"
                class="weight-400 align-middle detail-view-id not-button"
              >
                {{ data.item.serviceFormStringID }}
              </b-button>
            </b-button>
          </div>
        </template>

        <template #cell(workFlow)="data">
          <div class="text-nowrap">
            <span class="">{{ data.item.workFlowName }}</span>
            <br>
            <div v-if="$route.name === 'operation-service-forms-show'">
              <b-button
                v-if="data.item.fileNo"
                variant="link"
                style="font-size: 14px; color: #104D9D; padding: 0; display: flex; line-height: 1.358rem;"
                :to="{ name: 'operation-service-requests-index', params: { type: 'all-service-requests' }, query: { search: data.item.fileNo } }"
              >
                {{ data.item.fileNo }}
              </b-button>
              <span v-else>-</span>
            </div>
            <span v-else>File No: {{ data.item.fileNo || '-' }}</span>
          </div>
        </template>

        <template #cell(deceased)="data">
          <div class="text-nowrap">
            <span class="">Niche No: {{ data.item.serviceForm.deceasedNiche || '-' }}</span>
            <br>
            <span class="">Pedestal No: {{ data.item.serviceForm.deceasedPedestal || '-' }}</span>
            <br>
            <span class="">Anling Lot No.: {{ data.item.serviceForm.deceasedAngling || '-' }}</span>
          </div>
        </template>

        <template #cell(date)="data">
          <div class="text-nowrap">
            <span class="">{{ dateFormat(data.item.date) }},</span>
            <br>
            <span class="">{{ timeFormatFromDateString(data.item.date) }}</span>
          </div>
        </template>

        <template #cell(location)="data">
          <div class="text-nowrap">
            <span class="">{{ data.item.location }}</span>
            <br>
            <span class="">{{ timeFormatFromDateString(data.item.date) }}</span>
          </div>
        </template>

        <template #cell(serviceDescriptionValues)="data">
          <div
            v-if="data.item.showServiceDescription == true"
          >
            <div
              v-for="(opt, key) in data.item.serviceDescriptionValues"
              :key="key"
            >
              <div
                v-if="((Array.isArray(opt.value) && opt.value.length) || opt.value || (opt.children && opt.children.length))"
              >
                <span v-if="opt.children && opt.children.length">
                  <div
                    v-for="(child, indx) in opt.children"
                    :key="indx"
                  >
                    <div
                      v-if="((Array.isArray(child.value) && child.value.length) || child.value)"
                    >
                      <span
                        v-if="Array.isArray(child.value)"
                      >
                        <span
                          v-for="(option, idx) in child.value"
                          :key="idx"
                        >
                          {{ option }}
                          <feather-icon
                            :id="'unique_'+data.item._id+key+idx"
                            icon="AlertCircleIcon"
                            class="ml-50 text-primary"
                            size="14"
                          />
                          <b-tooltip
                            placement="auto"
                            :target="'unique_'+data.item._id+key+idx"
                            :title="child.key"
                          />
                        </span>
                      </span>
                      <span v-else-if="child.value">
                        <span
                          v-if="(child.type && ['increment', 'short-answer', 'long-answer'].includes(child.type))"
                        >
                          {{ child.key }} <span class="green-text">{{ child.value }}</span>
                        </span>
                        <span v-else>{{ child.key }} {{ child.value }}</span>
                      </span>
                    </div>
                  </div>
                </span>
                <span
                  v-if="Array.isArray(opt.value)"
                >
                  <span
                    v-for="(option, idx) in opt.value"
                    :key="idx"
                  >
                    {{ option }}
                    <feather-icon
                      :id="'unique_'+data.item._id+key+idx"
                      icon="AlertCircleIcon"
                      class="ml-50 text-primary"
                      size="14"
                    />
                    <b-tooltip
                      placement="auto"
                      :target="'unique_'+data.item._id+key+idx"
                      :title="opt.key"
                    />
                  </span>
                </span>
                <span v-else-if="opt.value">
                  <span
                    v-if="(opt.type && ['increment', 'short-answer', 'long-answer'].includes(opt.type))"
                  >
                    {{ opt.key }} <span class="green-text">{{ opt.value }}</span>
                  </span>
                  <span v-else>{{ opt.key }} {{ opt.value }}</span>
                </span>
              </div>
            </div>
          </div>
          <div
            v-else-if="data.item.serviceDescription && data.item.serviceDescription.length && !data.item.serviceDescription[0].id"
          >
            <div
              v-for="(opt, key) in data.item.serviceDescription"
              :key="key"
            >
              <div
                v-if="((Array.isArray(opt.value) && opt.value.length) || opt.value)"
              >
                <span
                  v-if="Array.isArray(opt.value)"
                >
                  <span
                    v-for="(option, idx) in opt.value"
                    :key="idx"
                  >
                    {{ option }}
                    <feather-icon
                      :id="'unique_'+data.item._id+key+idx"
                      icon="AlertCircleIcon"
                      class="ml-50 text-primary"
                      size="14"
                    />
                    <b-tooltip
                      placement="auto"
                      :target="'unique_'+data.item._id+key+idx"
                      :title="opt.key"
                    />
                  </span>
                </span>
                <span v-else-if="opt.value && opt.value != 0">
                  <span>{{ opt.key }}</span> <span class="green-text">{{ opt.value }}</span>
                </span>
              </div>
            </div>
          </div>
          <div v-else>
            -
          </div>
        </template>

        <template #cell(agentName)="data">
          <div class="text-nowrap">
            <span class="">{{ data.item.agentName || '-' }}</span>
            <br>
            <span class="">{{ data.item.agentCode || '-' }}</span>
            <br>
            <span
              v-if="data.item.agentContact"
              class=""
            >
              (65) {{ data.item.agentContact }}
            </span>
            <span
              v-else
              class=""
            >
              -
            </span>
          </div>
        </template>

        <template #cell(customerName)="data">
          <div class="text-nowrap">
            <span class="">{{ data.item.customerName }}</span>
            <br>
            <span
              v-if="data.item.customerContact"
              class=""
            >
              (65) {{ data.item.customerContact }}
            </span>
          </div>
        </template>

        <template #cell(assignees)="data">
          <div
            v-if="data.item.assignees.length"
            class="text-nowrap"
          >
            <span
              v-for="(assignee, key) in data.item.assignees"
              :key="key"
            >
              {{ assignee.name }}<span v-if="key < (data.item.assignees.length - 1)">, </span>
            </span>
          </div>
          <div v-else>
            -
          </div>
        </template>

        <template #cell(paymentStatus)="data">
          <div
            class="text-nowrap"
          >
            <b-badge
              :variant="`light-${resolveServiceRequestPaymentStatusVariant(data.item.paymentStatus)}`"
              class="text-capitalize"
            >
              {{ data.item.paymentStatus }}
            </b-badge>
            <!-- <br>
              <span
                class=""
              >
                {{ data.item.paymentRemarks || '-' }}
              </span> -->
          </div>
        </template>

        <!-- Column: Status -->
        <template #cell(status)="data">
          <b-badge
            :variant="`light-${resolveServiceRequestStatusVariant(data.item.status)}`"
            class="text-capitalize"
          >
            {{ data.item.status }}
          </b-badge>
        </template>

        <template #cell(freshness)="data">
          <span class="text-bold-black">{{ dateDifference(data.item.date) }}</span>
        </template>
        <template #custom-foot>
          <b-tr>
            <b-td :colspan="tableColumns.length">
              <div class="d-flex justify-content-between w-100">
                <span class="text-muted">Showing {{ dataMeta.from }} - {{ dataMeta.to }} of {{ dataMeta.of }}</span>
                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalRows"
                  :per-page="perPage"
                  align="right"
                  first-number
                  last-number
                  prev-class="prev-item"
                  next-class="next-item"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>
              </div>
            </b-td>
          </b-tr>
        </template>
      </b-table>
    </b-card>
  </b-col>
</template>

<script>
import {
  BCard, BCardTitle, BCol, BTable, BButton, VBTooltip, BPagination, BBadge, BTr, BTd, BTooltip,
} from 'bootstrap-vue'
import store from '@/store/index'
import moment from 'moment'
import Ripple from 'vue-ripple-directive'
import { useUtils as useAclUtils } from '@/libs/acl/custom'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

const { canViewThisAction } = useAclUtils()

export default {
  components: {
    BCard,
    BCardTitle,
    BCol,
    BTable,
    BButton,
    BPagination,
    BBadge,
    BTr,
    BTd,
    BTooltip,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  props: {
    widgetName: {
      type: [String, null],
      default: '',
    },
    statusOptions: {
      type: [Array, null],
      default: () => [],
    },
    paymentStatusOptions: {
      type: [Array, null],
      default: () => [],
    },
    filterOption: {
      type: [String, null],
      default: '',
    },
    dateOption: {
      type: [String, null],
      default: '',
    },
    dateRangeOption: {
      type: [Array, null],
      default: () => [],
    },
    tableColumns: {
      type: [Array, null],
      default: () => [],
    },
  },
  data() {
    return {
      user: store.state.auth.userData,
      render: false,
      sortBy: 'stringID',
      sortDesc: false,
      isSortDirDesc: true,
      tableItems: [],
      currentPage: 1,
      totalRows: 0,
      perPage: 10,
    }
  },
  computed: {
    dataMeta() {
      let totalPage = 1
      let localItems = 0
      if (this.totalRows) {
        totalPage = Math.ceil(this.totalRows / this.perPage)
        if (totalPage === this.currentPage) {
          localItems = this.totalRows - (this.perPage * (this.currentPage - 1))
        } else {
          localItems = 10
        }
      }
      return {
        from: this.perPage * (this.currentPage - 1) + (this.totalRows ? 1 : 0),
        to: this.perPage * (this.currentPage - 1) + localItems,
        of: this.totalRows,
      }
    },
  },

  watch: {
    $props: {
      handler() {
        this.getData()
      },
      deep: true,
      immediate: true,
    },
  },

  beforeMount() {
    this.getData()
  },

  methods: {
    canViewThisAction,
    getData() {
      const formData = new FormData()
      formData.append('filterOption', this.filterOption)
      formData.append('dateOption', this.dateOption)
      formData.append('sortBy', this.sortBy)
      formData.append('sortDesc', this.sortDesc)
      formData.append('dateRangeOption', JSON.stringify(this.dateRangeOption))
      formData.append('statusOptions', JSON.stringify(this.statusOptions))
      formData.append('paymentStatusOptions', JSON.stringify(this.paymentStatusOptions))

      this.$http.post('user/operation/service-request/find', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
        .then(response => {
          const { serviceRequests } = response.data
          this.tableItems = serviceRequests
          this.totalRows = serviceRequests.length
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    resolveServiceRequestStatusVariant(status) {
      if (status === 'completed') return 'success'
      if (status === 'pending') return 'warning'
      if (status === 'awaiting approval') return 'danger'
      return 'primary'
    },
    resolveServiceRequestPaymentStatusVariant(status) {
      if (status === 'Paid') return 'success'
      if (status === 'Partially Paid') return 'warning'
      if (status === 'Unpaid') return 'danger'
      return 'secondary'
    },
    dateDifference(date) {
      const timezone = process.env.VUE_APP_TIME_ZONE
      const appointmentDate = moment(date).utcOffset(timezone)
      const today = moment().utcOffset(timezone)

      const difference = appointmentDate.diff(today, 'days')

      if (difference === 0) return 'Today'
      if (difference === 1) return '1 day later'
      if (difference === -1) return '1 day ago'
      if (difference > 1) return `${difference} days later`
      if (difference < -1) return `${Math.abs(difference)} days ago`

      return ''
    },
    // eslint-disable-next-line consistent-return
    sortCompare(aRow, bRow, key) {
      if (key === 'freshness') {
        // eslint-disable-next-line no-nested-ternary
        return aRow.date < bRow.date ? -1 : aRow.date > bRow.date ? 1 : 0
      }
    },
  },
}
</script>
