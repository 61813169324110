<template>
  <b-col
    lg="12"
  >
    <b-card v-if="canViewThisAction('read', 'BudgetOverview')">
      <b-card-title class="mb-1 fs-21 pure-black">
        Budget Overview
      </b-card-title>
      <b-card-sub-title class="mb-2 text-primary-underline">
        {{ costCenterName }}: {{ expenseCategoryType }} {{ budgetaryPeriodTimeLine }}
      </b-card-sub-title>

      <b-table
        v-if="expenseCategoryType !== 'Non-budgeted'"
        ref="refAllExpenseTable"
        class="position-relative event_list"
        :items="budgetItems"
        responsive
        :fields="tableColumns"
        primary-key="_id"
        :sort-by.sync="sortBy"
        show-empty
        no-sort-reset
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >
        <template #cell(expenseCategoryName)="data">
          <b-button
            v-ripple.400="'rgba(30, 30, 30, 0.15)'"
            variant="link"
            class="weight-600 align-middle detail-view-id"
          >
            {{ data.item.expenseCategoryName }}
          </b-button>
          <br>
          <span class="finance__code-color">{{ data.item.code ? data.item.code : '-' }}</span>
        </template>

        <template #cell(allocated)="data">
          <span class="white-space-no-wrap">$ {{ numberFormat(data.item.allocated) }}</span>
        </template>

        <template #cell(spent)="data">
          <span class="white-space-no-wrap">$ {{ numberFormat(data.item.spent) }}</span>
        </template>

        <template #cell(remaining)="data">
          <span class="white-space-no-wrap">$ {{ numberFormat(data.item.remaining) }}</span>
        </template>

        <template #cell(usagePercentage)="data">
          <div>
            <b-progress
              v-b-tooltip.hover.v-primary.top="`${numberFormat(data.item.usagePercentage)}%`"
              max="100"
            >
              <b-progress-bar
                :value="data.item.usagePercentage"
                variant="primary"
              />
            </b-progress>
          </div>
        </template>
      </b-table>
      <b-table
        v-else
        ref="refAllNonBudgetExpenseTable"
        class="position-relative event_list"
        :items="nonBudgetItems"
        responsive
        :fields="nonBudgetTableColumns"
        primary-key="_id"
        :sort-by.sync="sortBy"
        show-empty
        no-sort-reset
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >
        <template #cell(expenseCategoryName)="data">
          <b-button
            v-ripple.400="'rgba(30, 30, 30, 0.15)'"
            variant="link"
            class="weight-600 align-middle detail-view-id"
          >
            {{ data.item.expenseCategory.name }}
          </b-button>
          <br>
          <span class="finance__code-color">{{ data.item.expenseCategory.code ? data.item.expenseCategory.code : '-' }}</span>
        </template>

        <template #cell(spent)="data">
          <span class="white-space-no-wrap">$ {{ numberFormat(data.item.sum) }}</span>
        </template>
      </b-table>
    </b-card>
  </b-col>
</template>

<script>
import {
  BCard, BCardTitle, BCardSubTitle, BCol, BProgress, BProgressBar, BTable, BButton, VBTooltip,
} from 'bootstrap-vue'
import store from '@/store/index'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { useUtils as useAclUtils } from '@/libs/acl/custom'

const { canViewThisAction } = useAclUtils()

export default {
  components: {
    BCard,
    BCardTitle,
    BCardSubTitle,
    BCol,
    BProgress,
    BProgressBar,
    BTable,
    BButton,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  props: {
    costCenter: {
      type: [String, null],
      default: '',
    },
    budgetaryPeriod: {
      type: [String, null],
      default: '',
    },
    expenseCategoryType: {
      type: [String, null],
      default: '',
    },
    expenseCategories: {
      type: [Array, null],
      default: () => [],
    },
  },
  data() {
    return {
      canViewThisAction,
      user: store.state.auth.userData,
      render: false,
      costCenterName: '',
      budgetaryPeriodTimeLine: '',
      sortBy: 'code',
      sortDesc: false,
      isSortDirDesc: false,
      nonBudgetItems: [],
      budgetItems: [],
      tableColumns: [
        {
          key: 'expenseCategoryName',
          label: 'sub budget (expense categories)',
          sortable: true,
        },
        {
          key: 'allocated',
          label: 'allocated budget',
          sortable: true,
        },
        {
          key: 'spent',
          label: 'spent',
          sortable: true,
        },
        {
          key: 'remaining',
          label: 'balance',
          sortable: true,
        },
        {
          key: 'usagePercentage',
          label: 'budget usage',
          sortable: true,
        },
      ],
      nonBudgetTableColumns: [
        {
          key: 'expenseCategoryName',
          label: 'sub budget (expense categories)',
          sortable: true,
        },
        {
          key: 'spent',
          label: 'spent',
          sortable: true,
        },
      ],
    }
  },

  watch: {
    $props: {
      handler() {
        this.getData()
      },
      deep: true,
      immediate: true,
    },
  },

  beforeMount() {
    this.getData()
  },
  methods: {
    getData() {
      if (this.costCenter && this.budgetaryPeriod && this.expenseCategoryType) {
        if (this.expenseCategoryType !== 'Non-budgeted') {
          const formData = new FormData()
          formData.append('costCenter', this.costCenter)
          formData.append('budgetPeriod', this.budgetaryPeriod)
          formData.append('expenseCategoryType', this.expenseCategoryType)
          formData.append('expenseCategories', JSON.stringify(this.expenseCategories))
          this.$http.post('user/budget/find/budgeted', formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
          })
            .then(response => {
              const { budgetItems: bItems, costCenterData, budgetPeriodData } = response.data
              this.costCenterName = costCenterData.name
              this.budgetaryPeriodTimeLine = `${this.dateFormat(budgetPeriodData.start)} - ${this.dateFormat(budgetPeriodData.end)}`
              const departmentArray = this.user.department.map(({ _id }) => _id)
              this.budgetItems = bItems.filter(o => {
                const filtered = o.expenseCategory.departments.filter(d => departmentArray.includes(d))
                return filtered.length
              })
            })
            .catch(error => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              })
            })
        } else {
          const formData = new FormData()
          formData.append('costCenter', this.costCenter)
          formData.append('budgetPeriod', this.budgetaryPeriod)
          formData.append('expenseCategoryType', this.expenseCategoryType)
          formData.append('expenseCategories', JSON.stringify(this.expenseCategories))
          this.$http.post('user/budget/find/non-budgeted', formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
          })
            .then(response => {
              const { budget, costCenterData, budgetPeriodData } = response.data
              this.costCenterName = costCenterData.name
              this.budgetaryPeriodTimeLine = `${this.dateFormat(budgetPeriodData.start)} - ${this.dateFormat(budgetPeriodData.end)}`
              const departmentArray = this.user.department.map(({ _id }) => _id)
              this.nonBudgetItems = budget.filter(o => {
                const filtered = o.expenseCategory.departments.filter(d => departmentArray.includes(d))
                return filtered.length
              })
            })
            .catch(error => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              })
            })
        }
      }
    },
  },
}
</script>
