<template>
  <b-col
    v-if="render && canViewThisAction('read', 'ServiceRequest')"
    class="d-inline"
    lg="6"
  >
    <b-card
      class="overview-card-height"
    >
      <b-card-title class="mb-1 fs-21 pure-black">
        Service Requests
      </b-card-title>
      <b-card-sub-title class="mb-2 black-color fs-16">
        Last 30D: Pending Status ({{ srCount }})
      </b-card-sub-title>

      <vue-apex-charts
        type="donut"
        height="250"
        :options="chartOptions"
        :series="dataSeries"
      />
    </b-card>
  </b-col>
</template>

<script>
import {
  BCard, BCardTitle, BCardSubTitle, BCol,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { useUtils as useAclUtils } from '@/libs/acl/custom'

const { canViewThisAction } = useAclUtils()

export default {
  components: {
    VueApexCharts,
    BCard,
    BCardTitle,
    BCardSubTitle,
    BCol,
  },
  data() {
    return {
      canViewThisAction,
      render: false,
      srCount: 0,
      dataSeries: [],
      chartOptions: {
        chart: {
          fontFamily: 'Montserrat',
        },
        legend: {
          show: true,
          position: 'right',
          fontSize: '14px',
          fontFamily: 'Montserrat',
          horizontalAlign: 'center',
          itemMargin: {
            horizontal: 0,
            vertical: 5,
          },
          formatter(seriesName, opts) {
            // return [seriesName, ' ', opts.w.globals.series[opts.seriesIndex]]
            return `<span class="legend-item-label black-color">${seriesName}</span> <span class="weight-600 legend-item-value">${opts.w.globals.series[opts.seriesIndex]}</span>`
          },
        },
        colors: [],
        dataLabels: {
          enabled: true,
          formatter(val, { seriesIndex, w }) {
            return w.config.series[seriesIndex]
            // eslint-disable-next-line radix
            // return `${parseInt(val)}%`
          },
        },
        plotOptions: {
          pie: {
            donut: {
              labels: {
                show: false,
                name: {
                  fontSize: '2rem',
                  fontFamily: 'Montserrat',
                },
                value: {
                  fontSize: '1rem',
                  fontFamily: 'Montserrat',
                  formatter(val) {
                    // eslint-disable-next-line radix
                    return `${parseInt(val)}`
                  },
                },
                total: {
                  show: false,
                  fontSize: '1.5rem',
                  label: 'Operational',
                  formatter() {
                    return '31%'
                  },
                },
              },
            },
          },
        },
        labels: [],
        responsive: [
          {
            breakpoint: 992,
            options: {
              chart: {
                height: 380,
              },
              legend: {
                position: 'bottom',
              },
            },
          },
          {
            breakpoint: 576,
            options: {
              chart: {
                height: 320,
              },
              plotOptions: {
                pie: {
                  donut: {
                    labels: {
                      show: true,
                      name: {
                        fontSize: '1.5rem',
                      },
                      value: {
                        fontSize: '1rem',
                      },
                      total: {
                        fontSize: '1.5rem',
                      },
                    },
                  },
                },
              },
              legend: {
                show: true,
              },
            },
          },
        ],
      },
    }
  },

  beforeMount() {
    this.$http.get('user/operation/service-request/pending/thirty-days')
      .then(response => {
        const result = response.data.srData
        let total = 0
        const series = []
        const labels = []
        const colors = []
        result.forEach(element => {
          total += element.totalCount

          series.push(element.totalCount)

          colors.push(element.workFlow.category.color)
          labels.push(element.workFlow.name)
        })

        this.srCount = total
        this.dataSeries = series
        this.chartOptions.labels = labels
        this.chartOptions.colors = colors

        this.render = true
      })
      .catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  },
}
</script>
